import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/MediaList.css'; // Ajoutez vos styles pour la liste des médias

const MediaList = () => {
  const [media, setMedia] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [captions, setCaptions] = useState(null);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get('http://localhost:5002/api/media');
        setMedia(response.data);
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    fetchMedia();
  }, []);

  const handleMediaClick = async (item) => {
    setSelectedMedia(item);
    setCaptions(null);
    if (item.mediaType === 1 || item.mediaType === 5) {
      try {
        const response = await axios.get(`http://localhost:5002/api/captions/${item.id}`);
        setCaptions(response.data.join('\n'));
      } catch (error) {
        console.error('Error fetching captions:', error);
      }
    }
  };

  return (
    <div className="media-list">
      <h2>Media List</h2>
      {selectedMedia ? (
        <div className="media-player">
          <h3>{selectedMedia.name}</h3>
          {selectedMedia.mediaType === 1 ? ( // mediaType 1 is for videos
            <div>
              <video controls width="600">
                <source src={selectedMedia.downloadUrl} type="video/mp4" />
                {captions && (
                  <track
                    srcLang="en"
                    label="English"
                    kind="subtitles"
                    src={`data:text/vtt;base64,${btoa(captions)}`}
                    default
                  />
                )}
                Your browser does not support the video tag.
              </video>
            </div>
          ) : selectedMedia.mediaType === 5 ? ( // mediaType 5 is for audios
            <div>
              <audio controls>
                <source src={selectedMedia.downloadUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              {captions && <pre>{captions}</pre>}
            </div>
          ) : (
            <p>Unsupported media type</p>
          )}
          <button onClick={() => setSelectedMedia(null)}>Back to list</button>
        </div>
      ) : (
        <ul>
          {media.map(item => (
            <li key={item.id} onClick={() => handleMediaClick(item)}>
              <h3>{item.name}</h3>
              <p>Type: {item.mediaType === 1 ? 'Video' : item.mediaType === 5 ? 'Audio' : 'Unknown'}</p>
              <p>Selected media type: {item.mediaType}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MediaList;
