import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [allData, setAllData] = useState([]);

  return (
    <DataContext.Provider value={{ allData, setAllData }}>
      {children}
    </DataContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte de données facilement
export const useData = () => useContext(DataContext);
