import React from 'react';

function SubtitleDisplay({ subtitle }) {
    return (
        <div className="subtitle-display" style={{ position: 'absolute', bottom: '10px', width: '100%', textAlign: 'center', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            {subtitle}
        </div>
    );
}

export default SubtitleDisplay;
