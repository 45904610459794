import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ResultComponent.css';
import { useSearchResults } from './SearchResultsContext';
import audioIcon from './icons/audio.png';
import videoIcon from './icons/video.png';

function ResultComponent() {
    const navigate = useNavigate();
    const { results } = useSearchResults();

    const handleResultClick = (result) => {
        const path = result.transcripted === "audio" ? "/audio/ad" : `/video/${result.uid_episode}`;
        navigate(path, {
            state: {
                audioUrlpath: result.audioUrlpath,
                title: result.title
            } 
        });
    };

    const handleSubtitleClick = (event, start, end, result) => {
        event.stopPropagation(); // Empêche l'événement de se propager aux éléments parents
        console.log(`Jouer de ${start} à ${end}`);
        console.log("resultsresultsresults********", result);
    
        // Condition pour filtrer les résultats en fonction du type de média de 'result'
        const filteredResults = result.transcripted === "audio"
            ? results.filter(item => item.transcripted === "Yes").slice(0, 10) // Si audio, filtrer pour "Yes"
            : results.filter(item => item.transcripted === "audio").slice(0, 10); // Sinon, filtrer pour les autres "audio"
    
        const path = result.transcripted === "audio" ? "/audios/ad" : `/videos/${result.uid_episode}`;
        navigate(path, {
            state: {
                audioUrlpath: result.audioUrlpath,
                title: result.title,
                start: start,
                end: end,
                relatedResults: filteredResults // Envoyer les 10 premiers résultats filtrés
            }
        });
    };
    
    
    

    const getBackgroundColor = (transcripted) => {
        return transcripted === "Yes" ? "background-color: #add8e6" : "lightgreen";
    };

    const getIcon = (transcripted) => {
        return transcripted === "Yes" ? videoIcon : audioIcon;
    };
console.log("resultsresultsresults",results)
    return (
        <div className="result-container">
            {results.map((result, index) => (
    <div
        key={index}
        className="result-item"
        onClick={() => handleResultClick(result)}
        style={{ backgroundColor: getBackgroundColor(result.transcripted) }}
    >
        <img src={getIcon(result.transcripted)} alt="Media Icon" className="media-icon" />
        <h3 className="result-title">{result.title}</h3>
        {result.parsed_subtitles.map((subtitle, subIndex) => (
            <div key={subIndex} className="subtitle-item" onClick={(event) => handleSubtitleClick(event, subtitle.start, subtitle.end, result)}>
                <p className="result-text">{subtitle.text}</p>
                <p className="result-meta">Temps : {subtitle.start} - {subtitle.end}</p>
            </div>
        ))}
    </div>
))}

        </div>
    );
}

export default ResultComponent;
