import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc,collection } from "firebase/firestore";
//import './signup.css'; // Assurez-vous que le chemin d'accès est correct
import styles from './signup.css';
import { useParams, useNavigate } from 'react-router-dom';
/* import logoImage from '../assets/logo_connexion2.png';  */// Importez l'image du logo depuis votre dossier d'assets
// Le reste de votre code de composant...

const SignUp = () => {
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const { firstName, lastName, email, password, confirmPassword } = formData;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Les mots de passe ne correspondent pas.");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Ajout des informations supplémentaires de l'utilisateur dans Firestore
            await setDoc(doc(db, "users", user.uid), {
                createdAt: new Date().toISOString(),
                firstName: firstName,
                lastName: lastName,
                email: email,
                uid:user.uid,
                app:"GotIt"
            });

            // Ajout de l'utilisateur dans l'historique des inscriptions
        const historyEntry = `${firstName} ${lastName} vient de rejoindre l'aventure.`;
        const historyDocRef = doc(collection(db, "inscript_Hist_GotIt"));
        await setDoc(historyDocRef, {
            entry: historyEntry,
            createdAt: new Date()
        });

             // Ajouter l'utilisateur à la collection 'observateur_RTVC' avec l'ID de l'utilisateur comme ID du document
      /*   await setDoc(doc(db, "Search_users", user.uid), {
            // Vous pouvez ajouter ici des informations supplémentaires si nécessaire
            // Par exemple, la date de création du compte ou le rôle de l'utilisateur
            createdAt: new Date().toISOString()
        }); */

            // Réinitialiser le formulaire ou rediriger l'utilisateur
            // setFormData({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' });
            // Vous pouvez rediriger l'utilisateur vers la page de connexion ou la page d'accueil après l'inscription

            console.log("Utilisateur créé avec succès:", user);
            // Après la création de l'utilisateur dans votre composant SignUp
navigate('/signin', { state: { userId: user.uid, email: user.email } });

        } catch (error) {
            console.error("Erreur lors de la création de l'utilisateur:", error.message);
        }
    };

    return (
        <div className={styles.bodyStyle}>
            <div className="logo-container">
                {/* <img src={logoImage} alt="Logo" className="signup-logo" /> {/* Affichez l'image du logo ici */} 
            </div>
            <h2>Inscription</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" name="firstName" value={firstName} onChange={handleChange} placeholder="Prénom" required />
                <input type="text" name="lastName" value={lastName} onChange={handleChange} placeholder="Nom" required />
                <input type="email" name="email" value={email} onChange={handleChange} placeholder="Adresse email" required />
                <input type="password" name="password" value={password} onChange={handleChange} placeholder="Mot de passe" required />
                <input type="password" name="confirmPassword" value={confirmPassword} onChange={handleChange} placeholder="Confirmez le mot de passe" required />
                <button type="submit">S'inscrire</button>
            </form>
        </div>
    );
};

export default SignUp;
