import React, { useState } from 'react';
import axios from 'axios';
import { useSearchResults } from './SearchResultsContext';
import ResultComponent from './ResultComponent';
import { useData } from './DataContext'; // Assurez-vous que ce hook fonctionne correctement pour la gestion des états
import './SearchComponentM.css';

function SearchComponent_off() {
  const [searchTerm, setSearchTerm] = useState('');
  const { setAllData } = useData(); // Simplification : utilise seulement si nécessaire pour mettre à jour le contexte global
  const { results, setResults } = useSearchResults();
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');

 // Gestion des recherches
  const handleSearch = async (event) => {
    event.preventDefault();
    if (!searchTerm.trim()) return;
    setIsLoading(true);
    try {
      const response = await axios.get(`https://search-engine-api.rtvc-cmonde.com/search`, {
        params: { query: searchTerm }
      });
      const results = Array.isArray(response.data) ? response.data : [];
      setResults(results);  // Mettre à jour avec les résultats ou un tableau vide
    } catch (error) {
      console.error('Error searching:', error);
      setResults([]);  // Effacer les résultats précédents en cas d'erreur
    } finally {
      setIsLoading(false);  // S'assurer de désactiver le chargement dans tous les cas
    }
};

  // Sauvegarde des données sur le serveur
  const saveDataToFile = async () => {
    try {
      const response = await axios.get(`https://search-engine-api.rtvc-cmonde.com/save_to_file`);
      alert('Data has been saved to file');
      const currentDate = new Date().toISOString();
      setLastUpdated(currentDate);
    } catch (error) {
      console.error('Error saving data: ', error);
    }
  };
console.log("resulats à afficher",results)
  return (
    <div className="container">
      {isLoading ? <p>Chargement en cours...</p> : (
        <div>
          <p className="info-text"> Base de données à jour : {lastUpdated}</p>
          <form onSubmit={handleSearch} className="search-form">
            <input
              type="text"
              placeholder="Entrez un mot ou une phrase"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <button type="submit" disabled={isLoading}>Rechercher</button>
            {/* <button onClick={saveDataToFile}>Sauvegarder Données</button> */}
          </form>
          <p className="results-text">Nombre de résultats pour {searchTerm} : {results.length}</p>
          <ResultComponent results={results} />
        </div>
      )}
    </div>
  );
}

export default SearchComponent_off;
