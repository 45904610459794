import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import SubtitleDisplay from './SubtitleDisplay';
import './VideoPlayer.css';

function VideoPlayer() {
    const { videoId } = useParams();
    const videoRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState("");
    const [overview, setoverview] = useState("");
    const [title, settitle] = useState("");

    // Fonction pour convertir le temps des sous-titres en secondes
    const convertToSeconds = (time) => {
        const parts = time.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseFloat(parts[2].replace(',', '.'));
        return hours * 3600 + minutes * 60 + seconds;
    };

    // Chargement des données vidéo et des sous-titres
    useEffect(() => {
        const fetchVideoData = async () => {
            const docRef = doc(firestore, "subtitles", videoId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setVideoUrl(data.video_path_hd?data.video_path_hd:data.audioUrlpath);
                setoverview(data.overview_TT);
                settitle(data.title);
                setSubtitles(data.parsed_subtitles || []); // Assume 'parsed_subtitles' contient les sous-titres
            } else {
                console.log("Aucune vidéo trouvée !");
            }
        };

        fetchVideoData();
    }, [videoId]);

    // Gestion de l'affichage des sous-titres en fonction du temps de la vidéo
    useEffect(() => {
        const handleTimeUpdate = () => {
            if (!videoRef.current) return;
            const currentTime = videoRef.current.currentTime;
            const sub = subtitles.find(s => {
              console.log("s",s)
                console.log("subtitles",subtitles)
                const startSeconds = convertToSeconds(s.start);
                const endSeconds = convertToSeconds(s.end);
                return currentTime >= startSeconds && currentTime <= endSeconds;
            });

            setCurrentSubtitle(sub ? sub.text : "");
        };

        const videoElement = videoRef.current;
        videoElement.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [subtitles]); // Dépend uniquement des sous-titres
console.log("currentSubtitle",currentSubtitle)
console.log("subtitles",subtitles)
return (
  <div className="video-container">
    <div>
            <h1> Vous regardez :{title}</h1> {/* Affichage du titre */}
            </div>
      <video ref={videoRef} src={videoUrl} controls className="video-player">
          Your browser does not support the video tag.
      </video>
      <p>SOUS-TITRES</p>
      <SubtitleDisplay subtitle={currentSubtitle} />
  </div>
);
}



export default VideoPlayer;
