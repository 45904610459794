import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import SubtitleDisplay from './SubtitleDisplay';
import './AudioPlayer.css';

function AudioPlayer_Time() {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const { audioUrlpath, title } = state || {};
    const audioRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState('');
    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState("");
    const [relatedResults, setRelatedResults] = useState(state.relatedResults || []);

    // Conversion du temps des sous-titres en secondes
    const convertToSeconds = (time) => {
        const parts = time.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseFloat(parts[2].replace(',', '.'));
        return hours * 3600 + minutes * 60 + seconds;
    };

    // Récupération des données audio et des sous-titres depuis Firestore
    useEffect(() => {
        if (!audioUrlpath) {
            console.log("Aucun chemin d'audio spécifié");
            return;
        }

        const fetchAudioData = async () => {
            const subtitlesRef = collection(firestore, "subtitles");
            const q = query(subtitlesRef, where("audioUrlpath", "==", audioUrlpath));
            const querySnapshot = await getDocs(q);
            let subs = [];
            querySnapshot.forEach((doc) => {
                subs = subs.concat(doc.data().parsed_subtitles);
                const drive_id = doc.data().drive_id;
                const audioStreamUrl = `https://search-engine-api.rtvc-cmonde.com/stream-audio?drive_id=${drive_id}`;
                setAudioUrl(audioStreamUrl);
                setSubtitles(subs);
            });
        };

        fetchAudioData();
    }, [audioUrlpath]);

    // Gestion du réglage du `currentTime` une fois que l'audio est chargé
    useEffect(() => {
        const audioElement = audioRef.current;
        if (audioElement && audioUrl && state && state.start) {
            const startSeconds = Math.max(0, convertToSeconds(state.start) - 10); // Start 10 seconds earlier
            const onCanPlay = () => {
                audioElement.currentTime = startSeconds;
                audioElement.play().catch(error => {
                    console.error("Erreur lors de la tentative de lecture automatique:", error);
                });
                audioElement.removeEventListener('canplaythrough', onCanPlay);
            };
            audioElement.addEventListener('canplaythrough', onCanPlay);
        }
    }, [audioUrl, state]);

    // Mise à jour du sous-titre courant en fonction du temps écoulé
    useEffect(() => {
        const handleTimeUpdate = () => {
            if (!audioRef.current) return;
            const currentTime = audioRef.current.currentTime;
            const sub = subtitles.find(s => {
                const startSeconds = convertToSeconds(s.start);
                const endSeconds = convertToSeconds(s.end);
                return currentTime >= startSeconds && currentTime <= endSeconds;
            });

            setCurrentSubtitle(sub ? sub.text : "");
        };

        const audioElement = audioRef.current;
        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        return () => audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    }, [subtitles]);

    return (
        <div className="audio-container">
            <h1>Vous écoutez : {title}</h1>
            <audio ref={audioRef} src={audioUrl} controls className="audio-player">
                Your browser does not support the audio tag.
            </audio>
            <SubtitleDisplay subtitle={currentSubtitle} className="subtitle-display" />
        </div>
    );
}

export default AudioPlayer_Time;
