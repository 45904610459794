import React, { useState, useEffect } from 'react';
import './ConfigPage.css';

const ConfigPage = () => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(null); // État pour stocker la date

    // Fonction pour sauvegarder les données sur le serveur
    const handleSaveToFile = async () => {
        setLoading(true);
        setMessage('');

        try {
            const response = await fetch('https://search-engine-api.rtvc-cmonde.com/save_to_file', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const result = await response.text();
                setMessage(result || 'Data successfully saved to file');
                fetchDateFromFile(); // Rafraîchit la date après la sauvegarde
            } else {
                setMessage('Failed to save data to file.');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMessage('An error occurred while trying to save data.');
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour charger la date depuis le serveur
    const fetchDateFromFile = async () => {
        try {
            const response = await fetch('https://search-engine-api.rtvc-cmonde.com/load_from_file');
            if (response.ok) {
                const data = await response.json();
                setDate(data.date); // Enregistre la date dans l'état
            } else {
                console.error('Erreur lors de la récupération de la date');
            }
        } catch (error) {
            console.error('Erreur de connexion:', error);
        }
    };

    // Utilisation de useEffect pour charger la date lors du chargement du composant
    useEffect(() => {
        fetchDateFromFile();
    }, []);

    return (
        <div className="config-page">
            <button 
                onClick={handleSaveToFile} 
                disabled={loading}
                className="config-page-button"
            >
                {loading ? 'Saving...' : 'Save Data to File'}
            </button>
            {message && (
                <p className={message.includes('successfully') ? 'success' : 'error'}>
                    {message}
                </p>
            )}
            {date ? (
                <p>Date in JSON file: {date}</p>
            ) : (
                <p>Loading date...</p>
            )}
        </div>
    );
};

export default ConfigPage;
