import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ListVideos from './ListVideos';
import VideoPlayer from './VideoPlayer';
import VideoPlayer_specifique from './VideoPlayer_specifique';
import AudioPlayer from './AudioPlayer';
import AudioPlayer_sepecific from './AudioPlayer_sepecific';
import AudioPlayer_Time from './AudioPlayer_time';
import SignUp from './signup';
import SignIn from './SignIn'; 
import ConfigPage from './ConfigPage'; 
import SearchComponent_off from './SearchComponent_main';
import { SearchResultsProvider } from './SearchResultsContext';
import { DataProvider } from './DataContext';
import AdminPage from './AdminPage'; // Importez le nouveau composant AdminPage
import MediaList from './Media_Kal'; // Importer le nouveau composant

function App() {
  return (
    <DataProvider>
    <SearchResultsProvider>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/list" element={<ListVideos />} />
          <Route path="/conf" element={<ConfigPage />} />
          <Route path="/video/:videoId" element={<VideoPlayer />} />
          <Route path="/videos/:videoId" element={<VideoPlayer_specifique />} />
          <Route path="/audio/:audioUrlpath" element={<AudioPlayer />} />
          <Route path="/audios/:audioUrlpath" element={<AudioPlayer_sepecific />} />
          <Route path="/audiot/:audioUrlpath" element={<AudioPlayer_Time />} />
          <Route path="/admin" element={<AdminPage />} /> {/* Ajoutez cette ligne pour la page admin */}
          <Route path="/media" element={<MediaList />} /> {/* Route pour le composant VideoList */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />    
          <Route path="/" element={<SearchComponent_off />} />
        </Routes>
      </div>
    </Router>
    </SearchResultsProvider>
    </DataProvider>
  );
}

export default App;
