// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBK7nmvzQ1Zmb2iiW2NAvJ-U8b8XloYKto",
  authDomain: "c-m-f-i-replay-f-63xui3.firebaseapp.com",
  projectId: "c-m-f-i-replay-f-63xui3",
  storageBucket: "c-m-f-i-replay-f-63xui3.appspot.com",
  messagingSenderId: "743892986646",
  appId: "1:743892986646:web:1f82cd0270676d74893e37"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Initialisez Firebase



// Exportez les instances Firestore et Storage
export { firestore,app };

