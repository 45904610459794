import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import SubtitleDisplay from './SubtitleDisplay';
import './VideoPlayer.css';

function VideoPlayer_specifique() {
    const navigate = useNavigate();
    const { videoId } = useParams();
    const { state } = useLocation();
    const videoRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState("");
    const [title, setTitle] = useState("");
    const [relatedResults, setRelatedResults] = useState(state.relatedResults || []);
    const [showRelatedResults, setShowRelatedResults] = useState(false);

    const convertToSeconds = (time) => {
        const parts = time.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseFloat(parts[2].replace(',', '.'));
        return hours * 3600 + minutes * 60 + seconds;
    };

    useEffect(() => {
        const fetchVideoData = async () => {
            const docRef = doc(firestore, "subtitles", videoId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setVideoUrl(data.video_path_hd ? data.video_path_hd : data.audioUrlpath);
                setTitle(data.title);
                setSubtitles(data.parsed_subtitles || []);
            } else {
                console.log("Aucune vidéo trouvée !");
            }
        };

        fetchVideoData();
    }, [videoId]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement && videoUrl && state && state.start) {
            const startSeconds = Math.max(0, convertToSeconds(state.start) - 10); // Démarrer 10 secondes plus tôt
            const onCanPlay = () => {
                videoElement.currentTime = startSeconds;
                videoElement.play().catch(error => {
                    console.error("Erreur lors de la tentative de lecture automatique:", error);
                });
                videoElement.removeEventListener('canplaythrough', onCanPlay);
            };
            videoElement.addEventListener('canplaythrough', onCanPlay);
        }
    }, [videoUrl, state]);

    const handleTimeUpdate = () => {
        if (!videoRef.current) return;
        const currentTime = videoRef.current.currentTime;
        const sub = subtitles.find(s => {
            const startSeconds = convertToSeconds(s.start);
            const endSeconds = convertToSeconds(s.end);
            return currentTime >= startSeconds && currentTime <= endSeconds;
        });

        setCurrentSubtitle(sub ? sub.text : "");
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        videoElement.addEventListener('timeupdate', handleTimeUpdate);
        return () => videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    }, [subtitles]);

    const handleRelatedResultClick = (item) => {
        navigate(`/videos/${item.uid_episode}`, {
            state: {
                audioUrlpath: item.video_path_hd,
                title: item.title,
                relatedResults: relatedResults
            }
        });
    };

    const toggleRelatedResults = () => {
        setShowRelatedResults(!showRelatedResults);
    };

    return (
        <div className="video-container">
            <h1>Vous regardez : {title}</h1>
            <video 
                ref={videoRef} 
                src={videoUrl} 
                controls 
                className="video-player" 
                preload="auto" 
                onContextMenu={(e) => e.preventDefault()} // Désactiver le clic droit
            >
                Your browser does not support the video tag.
            </video>
            <SubtitleDisplay subtitle={currentSubtitle} className="video-subtitle-display" />
            <button onClick={toggleRelatedResults} className="toggle-button">
                {showRelatedResults ? 'Fermer' : 'Voir les titres similaires'}
            </button>
            <p>
                        
                    </p>
            {showRelatedResults && (
                <div className="video-related-results">
                    <h2>Titres similaires</h2>
                    {relatedResults.map((item, index) => (
                        <div key={index} className="video-related-item" onClick={() => handleRelatedResultClick(item)}>
                            <p>{item.title}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default VideoPlayer_specifique;
