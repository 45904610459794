import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import SubtitleDisplay from './SubtitleDisplay';
import './AudioPlayer.css';

function AudioPlayer() {
    const location = useLocation();
    const { audioUrlpath, title } = location.state || {}; // Extraction du titre depuis le state
    const audioRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState('');
    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState("");
    console.log("audioUrlpath", audioUrlpath);
    useEffect(() => {
        if (!audioUrlpath) return; // Ajout d'une vérification pour éviter des appels inutiles

/*         const fetchAudioData = async () => {
            console.log("audioUrlpath***********", audioUrlpath);
            const subtitlesRef = collection(firestore, "subtitles");
            const q = query(subtitlesRef, where("audioUrlpath", "==", audioUrlpath));
            const querySnapshot = await getDocs(q);
            let subs = [];
            querySnapshot.forEach((doc) => {
                subs = subs.concat(doc.data().parsed_subtitles);
                const drive_id = doc.data().drive_id;
                console.log("drive_id",drive_id)

                fetch(`http://localhost:5100/audio?drive_id=${drive_id}`)
                    .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok');
                        return response.blob();
                    })
                    .then(blob => {
                        const url = URL.createObjectURL(blob);
                        setAudioUrl(url);
                    })
                    .catch(error => {
                        console.error('Error fetching audio:', error);
                        setAudioUrl(''); // Gestion d'erreur: réinitialiser l'URL en cas d'erreur
                    });

                setSubtitles(subs);
            });
        }; */

        const fetchAudioData = async () => {
            console.log("audioUrlpath***********", audioUrlpath);
            const subtitlesRef = collection(firestore, "subtitles");
            const q = query(subtitlesRef, where("audioUrlpath", "==", audioUrlpath));
            const querySnapshot = await getDocs(q);
            let subs = [];
            querySnapshot.forEach((doc) => {
                subs = subs.concat(doc.data().parsed_subtitles);
                const drive_id = doc.data().drive_id;
                console.log("drive_id", drive_id);
        
                // Définir directement l'URL de l'audio pour streaming
                const audioStreamUrl = `https://search-engine-api.rtvc-cmonde.com/stream-audio?drive_id=${drive_id}`;
                setAudioUrl(audioStreamUrl);  // Assumer que setAudioUrl met à jour l'URL de l'élément audio
        
                setSubtitles(subs);
            });
        };
        

        fetchAudioData();
    }, []); // Dépendance sur audioUrlpath pour éviter des appels inutiles

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (!audioRef.current) return;
            const currentTime = audioRef.current.currentTime;
            const sub = subtitles.find(s => {
                const startSeconds = convertToSeconds(s.start);
                const endSeconds = convertToSeconds(s.end);
                return currentTime >= startSeconds && currentTime <= endSeconds;
            });

            setCurrentSubtitle(sub ? sub.text : "");
        };

        const audioElement = audioRef.current;
        if (audioElement) {
            audioElement.addEventListener('timeupdate', handleTimeUpdate);
            return () => audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        }
    }, [subtitles]);

    const convertToSeconds = (time) => {
        if (!time) return 0;
        const parts = time.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseFloat(parts[2].replace(',', '.'));
        return hours * 3600 + minutes * 60 + seconds;
    };

    return (
        <div className="audio-container">
            <h1>Vous écoutez : {title}</h1>
            <audio ref={audioRef} src={audioUrl} controls className="audio-player">
                Your browser does not support the audio tag.
            </audio>
            <SubtitleDisplay subtitle={currentSubtitle} className="subtitle-display" />
        </div>
    );
}

export default AudioPlayer;
