import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './styles/AdminPage.css'; // Ajoutez vos styles pour la page admin

const AdminPage = () => {
  return (
    <div className="admin-page">
      <h2>Admin Page</h2>
      <Stack spacing={2} direction="column" alignItems="center" className="admin-menu">
        <Button variant="contained" color="primary" component={Link} to="/transcription">
          Transcription
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/generate-links">
          Produire des liens
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/">
          Application
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/another-feature">
          Autre
        </Button>
      </Stack>
    </div>
  );
};

export default AdminPage;
