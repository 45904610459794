import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

function ListVideos() {
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    async function fetchEpisodes() {
      try {
        const querySnapshot = await getDocs(collection(firestore, "subtitles"));
        const episodesArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEpisodes(episodesArray);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchEpisodes();
  }, []);
console.log("episodes",episodes)
  return (
    <div>
      {episodes.map(episode => (
        <div key={episode.id}>
        <Link to={{
    pathname: `/video/${episode.id}`,
    state: { videoUrl: episode.video_path_hd }
}}>
    {episode.title || 'No Title'}
</Link>
        <p>Runtime: {episode.runtime_h_m}</p>
        <p>Overview: {episode.overview_TT}</p>
        <video controls src={episode.video_path_hd} style={{ width: '100%' }}>
            Your browser does not support the video tag.
        </video>
    </div>
      ))}
    </div>
  );
}

export default ListVideos;
