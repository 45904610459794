import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firestore,app } from './firebaseConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './SignIn.css';
/* import logoImage from '../assets/logo_connexion2.png'; */ // Importez l'image du logo depuis votre dossier d'assets

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const navigate = useNavigate();

  const auth = getAuth(app);
  const db = getFirestore(app);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
        console.log("*****In*****")
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("user",user)
      //navigate('/rubriques');
      // Vérification si l'utilisateur est autorisé
      const userDocRef = doc(db, "users", user.uid);
      console.log("userDocRef",userDocRef)
      const userDoc = await getDoc(userDocRef);
      console.log("userDoc",userDoc)
      
      if (userDoc.exists()) {
        console.log('Connexion réussie et utilisateur autorisé');
        setLoginMessage('Connexion réussie');
        navigate('/');
        // L'utilisateur est autorisé, continuez avec la logique de votre application
      } else {
        console.error('Utilisateur non autorisé');
        setLoginMessage('Erreur de connexion'); 
        setError('Votre compte n\'est pas autorisé à accéder à cette application.');
        // Déconnexion de l'utilisateur
        auth.signOut();
      }
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.message);
      setError(error.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Connexion réussie');
      setLoginMessage('Connexion réussie');
      navigate('/HomePage'); // Redirige l'utilisateur vers la page Series
      // Redirection ou mise à jour de l'état de connexion ici
    } catch (error) {
      console.error('Erreur de connexion:', error.message);
      setLoginMessage('Erreur de connexion');   
    }
  };

  return (
    <div className={styles.bodyStyle}>
      <div className="logo-container">
                {/* <img src={logoImage} alt="Logo" className="signup-logo" /> {/* Affichez l'image du logo ici */} 
            </div>
      <form>
      <h1>Connexion</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Mot de passe"
      />
      <button onClick={handleSignIn}>Se connecter</button>
       <p>Vous n'avez pas de compte ? <Link to="/signup">Créer un compte</Link></p> 
      </form>
      {loginMessage && <p>{loginMessage}</p>}
    </div>
  );
};

export default SignIn;
